<template>
  <div>




    <v-card elevation="0">
      <v-card-title class="text-lg">
        <v-spacer/>
        Are you Ready to list and & grow your business?
        Join the Mango & Lola wedding community and get discovered by newly engaged couples...
        <v-spacer/>
      </v-card-title>

      <v-card-text>
        {{this.formData}}
        <v-stepper class="elevation-0" v-model="e1">
          <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                step="1"
                :color="valid1 ? 'primary':'red'"
            >
              Introduce yourself...
            </v-stepper-step>

            <v-stepper-step
                :complete="e1 > 2"
                step="2"
                :color="valid2 ? 'primary':'red'"
            >
              How many categories are you adding?
            </v-stepper-step>

            <v-stepper-step
                :complete="e1 > 3"
                step="3"
                :color="valid3 ? 'primary':'red'"
            >
              Calendar & Availability
            </v-stepper-step>

            <v-stepper-step
                :complete="e1 > 4"
                step="4"
                :color="valid4 ? 'primary':'red'"
            >
              Area of Coverage?
            </v-stepper-step>

            <v-stepper-step step="5" :color="valid5 ? 'primary':'red'">
              Finishing Up! With terms of use and privacy policy.
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>



            <v-stepper-content step="1">
              <v-form ref="form1" v-model="valid1" :lazy-validation="lazy" >
                <v-card elevation="0">
                  <v-card-text>

                    <div class="row">
                      <div class="col">
                        <v-text-field
                            v-model="formData.step1.name"
                            label="Name *"
                            :rules="[rules.required]"
                            required
                            tabindex="1"
                            clearable
                            append-icon="mdi-account"
                            autocomplete="new-password"
                        >
                        </v-text-field>

                        <!-- owner_first_name -->
                        <v-text-field
                            v-model="formData.step1.owner_first_name"
                            label="Enter your owner first name *"
                            :rules="[rules.required]"
                            required
                            tabindex="3"
                            clearable
                            append-icon="mdi-account"
                            autocomplete="new-password"
                        >
                        </v-text-field>



                      </div>
                      <div class="col">

                        <!-- address1 -->
                        <v-text-field
                            v-model="formData.step1.address1"
                            label="Enter your address *"
                            :rules="[rules.required]"
                            required
                            tabindex="2"
                            clearable
                            append-icon="mdi-home"
                            autocomplete="new-password"
                        >
                        </v-text-field>

                        <!-- owner_last_name -->
                        <v-text-field
                            v-model="formData.step1.owner_last_name"
                            label="Enter your owner last name *"
                            :rules="[rules.required]"
                            required
                            tabindex="4"
                            clearable
                            append-icon="mdi-account"
                            autocomplete="new-password"
                        >
                        </v-text-field>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col">
                        <v-autocomplete
                            class="text-caption remove-bg-fix"
                            dense
                            v-model="selectedCities"
                            :items="cities_by_google"
                            :search-input.sync="searchCities"
                            :hide-no-data="!searchCities"
                            :loading="loadingCities"
                            hint="Boston"
                            :persistent-hint="false"
                            append-icon=""
                            label="City"
                            :rules="[rules.required1]"
                            :clearable="true"
                            :hide-selected="false"
                            :deletable-chips="true"
                            @click="clearAreas"
                            :no-filter="true"
                            :cache-items="false"
                        >


                          <template v-slot:append>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_388_388)">
                                <path d="M14 7.16663C14 11.8333 8 15.8333 8 15.8333C8 15.8333 2 11.8333 2 7.16663C2 5.57533 2.63214 4.0492 3.75736 2.92399C4.88258 1.79877 6.4087 1.16663 8 1.16663C9.5913 1.16663 11.1174 1.79877 12.2426 2.92399C13.3679 4.0492 14 5.57533 14 7.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 9.16663C9.10457 9.16663 10 8.2712 10 7.16663C10 6.06206 9.10457 5.16663 8 5.16663C6.89543 5.16663 6 6.06206 6 7.16663C6 8.2712 6.89543 9.16663 8 9.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_388_388">
                                  <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </template>

                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-text="data.item.description"></v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <template v-slot:selection="data">
                            <v-chip small>
                              {{data.item.city_name}}
                            </v-chip>
                          </template>


                        </v-autocomplete>
                        {{selectedCities}}
                        <hr>
                        C:  {{formData.step1.city}}
                        S:   {{formData.step1.state}}
                        <hr>
                      </div>
                      <div class="col">

                        <div v-if="cities[0]">
                          {{cities[0].city_name}} - {{cities[0].country_name}} ({{cities[0].country_code}})

                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col">
                        <!-- phone
                <vue-phone-number-input
                v-model="formData.step1.phone"
                :only-countries="['US']"
                tabindex="7"
                :no-country-selector="true"
                :border-radius="0"

                required
                />
                        -->

                        <v-text-field
                            v-model="formData.step1.phone"
                            label="Enter your phone *"
                            :rules="[rules.required]"
                            required
                            tabindex="7"
                            clearable
                            append-icon="mdi-phone"
                            autocomplete="new-password"
                            type="number"

                        >
                        </v-text-field>

                      </div>
                      <div class="col">
                        <!-- tin -->
                        <v-text-field
                            v-model="formData.step1.tin"
                            label="Enter your Tax Identification Number"
                            tabindex="8"
                            clearable
                            append-icon="mdi-bank"
                            autocomplete="new-password"
                        >
                        </v-text-field>

                      </div>

                    </div>
                    <div class="row">
                      <div class="col">
                        <!-- instagram_link -->
                        <v-text-field
                            v-model="formData.step1.instagram_link"
                            label="Enter your Instagram page link"
                            tabindex="9"
                            clearable
                            append-icon="mdi-instagram"
                            autocomplete="new-password"
                        >
                        </v-text-field>
                      </div>
                      <div class="col">
                        <!-- facebook_link -->
                        <v-text-field
                            v-model="formData.step1.facebook_link"
                            label="Enter your Facebook page link"
                            tabindex="10"
                            clearable
                            append-icon="mdi-facebook"
                            autocomplete="new-password"
                        >
                        </v-text-field>
                      </div>
                    </div>

                  </v-card-text>
                </v-card>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="form2" v-model="valid2" :lazy-validation="lazy" >
                <v-card>
                  <v-card-title>
                    <v-spacer/>
                    Choose your service categories...
                    <v-spacer/>
                  </v-card-title>
                  <v-card-subtitle>
                    For each categories 3 offerings will be generated.
                    Add description, photo and price and you are ready to publish!
                  </v-card-subtitle>
                  <v-card-text>

                    <v-alert
                        v-if="!valid2 && this.nextStep == this.e1+1"
                        dense
                        outlined
                        type="error"
                    >
                      Categories is required
                    </v-alert>

                    <hr>

                    <v-chip-group
                        v-model="formData.step2.categories"
                        column
                        multiple
                        active-class="deep-purple darken-4"
                        mandatory
                    >

                      <v-chip v-for="category in categories"
                              :key="category.id"
                              filter
                              outlined
                              :value="category.id"
                      >

                        <v-icon left>{{category.mdi}}</v-icon>
                        {{category.name}}
                      </v-chip>

                    </v-chip-group>


                  </v-card-text>
                </v-card>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form ref="form3" v-model="valid3" :lazy-validation="lazy" >
                <v-card>
                  <v-card-text>
                    <div class="row">
                      <div class="col">
                        <v-card max-height="300">
                          <v-card-title>
                            Vendor availability option
                          </v-card-title>
                          <v-card-subtitle>
                            <strong>All dates open (available)</strong>
                            <p>But do not worry, you can always change this setting later on.</p>
                          </v-card-subtitle>
                          <v-card-text>
                            <v-radio-group v-model="formData.step3.availability_check" :mandatory="true" >
                              <v-radio label="As a Vendor I can provide only one offer at time." value="vendor"></v-radio>
                              <v-radio label="As a Vendor I'm able to offer more than one service in same time." value="package"></v-radio>
                            </v-radio-group>

                          </v-card-text>
                        </v-card>

                      </div>
                      <div class="col">
                        <v-card max-height="300">
                          <v-card-title>

                            <v-spacer/>
                            <v-btn icon small @click="dialogStep3 = !dialogStep3">
                              <v-icon >mdi-help</v-icon>
                              Vendor booking option
                            </v-btn>
                          </v-card-title>
                          <v-card-subtitle>
                            Select one of booking options!
                          </v-card-subtitle>
                          <v-card-text>

                            <v-radio-group
                                v-model="formData.step3.auto_booking"
                                mandatory
                            >
                              <v-radio
                                  label="Booking request"
                                  :value="false"
                              ></v-radio>
                              <v-radio
                                  label="Auto-Booking"
                                  :value="true"
                              ></v-radio>

                            </v-radio-group>

                          </v-card-text>

                        </v-card>

                      </div>
                    </div>


                  </v-card-text>
                </v-card>
              </v-form>

              <v-dialog
                  v-model="dialogStep3"

              >
                <BookingOption/>
              </v-dialog>

            </v-stepper-content>

            <v-stepper-content step="4">
              <v-form ref="form4" v-model="valid4" :lazy-validation="lazy" >
                <v-alert
                    v-if="(!valid4)"
                    dense
                    outlined
                    type="error"
                >
                  Please search and chose at least one state
                </v-alert>

                <v-card>
                  <v-card-title>
                    <v-spacer/>
                    Area of Coverage
                    <v-spacer/>
                  </v-card-title>
                  <v-card-text>


                    <v-autocomplete
                        v-model="formData.step4.countries"
                        :items="countries"
                        item-value="id"
                        item-text="name"
                        :rules="[rules.required]"
                        label="States selection *"
                        prepend-icon="mdi-map"
                        multiple
                        chips
                        :clearable="true"
                        :hide-selected="true"
                        :deletable-chips="true"
                    >

                    </v-autocomplete>


                  </v-card-text>
                </v-card>
              </v-form>

            </v-stepper-content>

            <v-stepper-content step="5">
              <v-form ref="form5" v-model="valid5" :lazy-validation="lazy" >
                <v-alert
                    v-if="(!valid5)"
                    dense
                    outlined
                    type="error"
                >
                  Privacy & Terms are required
                </v-alert>
                <div class="row">
                  <div class="col-6">
                    <v-card class="mx-auto">
                      <v-card-title>
                        {{privacy.name}}
                      </v-card-title>
                      <v-card-text>
                        {{privacy.description}}
                      </v-card-text>
                      <v-card-actions>
                        <v-checkbox v-model="formData.step5.privacy_validate"
                                    :rules="[rules.required1]"
                                    :value="privacy.id"
                                    class="mx-2" :label="privacy.name"></v-checkbox>
                      </v-card-actions>
                    </v-card>
                  </div>
                  <div class="col-6">
                    <v-card class="mx-auto">
                      <v-card-title>
                        {{terms.name}}
                      </v-card-title>
                      <v-card-text>
                        {{terms.description}}
                      </v-card-text>
                      <v-card-actions>
                        <v-checkbox v-model="formData.step5.terms_validate"
                                    :rules="[rules.required1]"
                                    :value="terms.id"

                                    class="mx-2" :label="terms.name"></v-checkbox>
                      </v-card-actions>
                    </v-card>
                  </div>
                </div>
              </v-form>
            </v-stepper-content>

            <v-card-actions>
              <v-btn v-if="e1 != 1" @click="e1 = e1-1"
                     rounded
                     color="primary"
              >
                Previous
              </v-btn>
              <v-spacer/>
              <v-btn
                  rounded
                  color="primary"
                  v-if="e1 != 5"
                  @click="next(e1)"
                  :disabled="!valid1 || !valid2 || !valid3 || !valid4 || !valid5"
              >
                Next
              </v-btn>

              <v-btn v-else
                     rounded
                     color="primary"
                     @click="next(e1)"
                     :disabled="!valid1 || !valid2 || !valid3 || !valid4 || !valid5"
              >
                Finish
              </v-btn>


            </v-card-actions>
            <small>* indicates required field</small>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>




    </v-card>

  </div>
</template>


<script>

//import {FormWizard, TabContent, ValidationHelper} from 'vue-step-wizard'
//import 'vue-step-wizard/dist/vue-step-wizard.css'

//import { required } from "vuelidate/lib/validators";
//import { validationMixin } from 'vuelidate'

//import { debounce } from 'vue-debounce'

import BookingOption from '../components/help/BookingOptions.vue'

export default {
  middleware: ['auth','vendor'],
  components: {
    //FormWizard,
    //TabContent
    BookingOption
  },
  metaInfo () {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  data() {
    return {
      loading: false,
      e1: 1,
      formData: {
        step1: {
          name: null,
          owner_first_name: null,
          owner_last_name: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          country: [],
          phone: null,
          tin: null,
          instagram_link: null,
          facebook_link: null,
        },
        step2: {
          categories: []
        },
        step3: {
          set_my_available_dates: 1,
          availability_check: 'package',
          auto_booking: true,
        },
        step4: {
          countries: [],
          cities: [],
        },
        step5: {
          privacy_validate: '',
          terms_validate: ''
        },

      },
      categories: [],
      countries: [],
      cities: [],
      searchCities: null,
      loadingCities: false,
      searchCity: null,
      loadingCity: false,
      errored: false,
      privacy: '',
      terms: '',
      rules: {
        required1: value => !!value || 'Required.',
        required: v => !!(v && v.length) || 'Item is required',
        min: v => v.length >= 8 || 'Min 8 characters',
        passwordRules: v => !!v || "Password is required",
        confirmPasswordRules: v => !!v || "Confirmation password is required",
        zip: v => /^[0-9]{5}([- ]?[0-9]{4})?$/.test(v) || 'U.S. postal code required (ZIP+4 format)',
        phone: v => /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(v) || 'U.S. phone format required (eg. (123) 123-1234)'

      },
      valid: true,
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      valid5: true,
      lazy: true,
      dialogStep3: false,
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      cities_by_google: [],
      filterArea: {},
      filterCountry: [],
      selectedCities: []
      // END
    }

  },
  /*
  validations: {
      formData:{
          step1: {
              name: {required},
              owner_first_name: {required},
              owner_last_name: {required},
              address1: {required},
              city: {required},
              state: {required},
              phone: {required},
          },
          step2: {
              categories: {required}
          },
          step3: {
              set_my_available_dates: {required},
              availability_check: {required}
          },
          step4: {
              countries: {required}
          },
          step5:{
              privacy_validate: {required},
              terms_validate: {required},
          }
      },
      //validationGroup: ['step1', 'step2']
  },
  */
  mounted: function(){
    this.getformData();
    this.fetchCategories();
    this.fetchPrivacy();
    this.fetchTerms();
    this.fetchCountries();
  },
  methods:{
    clearAreas(){
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit () {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions (predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities_by_google = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value:null,
        country: t.terms[2] ? t.terms[2].value:null,
      }));

    },
    nameWithCode ({ name, code }) {
      return `${name} - [${code}]`;
    },
    next(value){

      var vl;

      if(value == 1){
        vl = this.validateStep('step1');
      }else if (value == 2){
        vl = this.validateStep('step2');
      }else if (value == 3){
        vl = this.validateStep('step3');
      }else if (value == 4){
        vl = this.validateStep('step4');
      }else if (value == 5){
        vl = this.validateStep('step5');
      }


      if(vl){
        if(value == 5){
          console.log("complete");
          this.onComplete();
        }else{
          this.e1 = this.e1+1;
        }

      }else{
        console.log("Validation fail");
      }

    },
    validateStep(name) {

      // $v.formData.step2.categories.required --- to valid specific part

      //return true;
      var isValid = false;
      if(name == 'step1'){
        //return true;
        isValid = this.$refs.form1.validate();
        //if (!this.valid) return;
        ///this.$v.formData.step1.$touch();
        //isValid = !this.$v.formData.step1.$invalid
        console.log("validation 1: " + isValid);
        return isValid
      }else if (name == 'step2'){
        //return true;
        isValid = this.$refs.form2.validate();
        //if (!this.valid) return;
        //this.$v.formData.step2.$touch();
        //isValid = !this.$v.formData.step2.$invalid
        console.log("validation 2: " + isValid);
        return isValid
      }else if (name == 'step3'){
        //return true;
        isValid = this.$refs.form3.validate();
        //this.$v.formData.step3.$touch();
        //isValid = !this.$v.formData.step3.$invalid
        console.log("validation 3: " + isValid);
        return isValid
      }else if (name == 'step4'){
        //return true;
        isValid = this.$refs.form4.validate();
        //if (!this.valid) return;
        //this.$v.formData.step4.$touch();
        //isValid = !this.$v.formData.step4.$invalid
        console.log("validation 4: " + isValid);
        this.valid = isValid;
        return isValid
      }else if (name == 'step5'){
        isValid = this.$refs.form5.validate();
        //if (!this.valid) return;
        //this.$v.formData.step5.$touch();
        //isValid = !this.$v.formData.step5.$invalid
        console.log("validation 5: " + isValid);
        return isValid
      }else{
        return isValid;
      }
    },
    /*
     validate() {
        this.$v.formData.$touch();
        var isValid = !this.$v.formData.$invalid
        //this.$emit('on-validate', this.$data, isValid)
        return isValid
    },
    */
    async onComplete(){
      console.log("...");

      this.$http.post('/api/vendor/onboarding',this.formData)
          .then(({data})  => {
            this.$toast.success(data.message, "Success"),
                // Fetch the user.
                this.$store.dispatch('auth/fetchUser')
          })
          .catch((error) => {
            // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              //console.log('Error response', error.response);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log('Error request.response', error.request.response);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error message', error.message);
            }
            //console.log('Error', error);
          })
          .finally(() =>
              this.$router.push({name: 'feed'})
          )

    },
    getformData(){
      console.log("formData" + this.formData.name);
      this.loading = true;
      let uri = '/api/vendor/companies';
      this.$http.get(uri).then((response) => {
        this.formData.step1.name = response.data.data.name,
            this.formData.step1.owner_first_name = response.data.data.owner_first_name,
            this.formData.step1.owner_last_name = response.data.data.owner_last_name,
            this.formData.step1.address1 = response.data.data.address1,
            this.formData.step1.city = response.data.data.city,
            this.formData.step1.state = response.data.data.state,
            this.formData.step1.country = response.data.data.country,
            this.formData.step1.phone = response.data.data.phone,
            this.formData.step1.tin = response.data.data.tin,
            this.formData.step1.instagram_link = response.data.data.instagram_link,
            this.formData.step1.facebook_link = response.data.data.facebook_link,

            this.loading = false
      });
    },
    fetchCategories () {
      this.loading = true;
      this.$http.get('api/categories')
          .then(({data})  => {
            this.categories = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchPrivacy () {
      this.loading = true;
      this.$http.get('api/privacy')
          .then(({data})  => {
            this.privacy = data.data,
                this.formData.step5.privacy_validate = data.data.id;
          })
          .catch(error => {
            console.log('Error loading privacy data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchTerms () {
      this.loading = true;
      this.$http.get('api/terms')
          .then(({data})  => {
            this.terms = data.data
            this.formData.step5.terms_validate = data.data.id;
          })
          .catch(error => {
            console.log('Error loading terms data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCountries() {
      this.loading = true;
      this.$http.get('api/countries')
          .then(({data})  => {
            this.countries = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCities(query) {
      console.log(query);
      const params = {
        "filter[name]" : query,
        "filter[country_id]" : this.formData.step4.countries,
        "page": this.$route.query.page
      };

      this.loadingCities = true;
      this.$http.get('api/cities', {params})
          .then(({data})  => {
            this.cities = this.cities.concat(data.data);
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCities = false
          )

    },
    fetchCity(query) {

      const params = {
        "filter[name]" : query,
        "filter[country_id]" : this.formData.step1.state,
        "page": this.$route.query.page
      };
      //console.log(params);
      this.loadingCity = true;
      this.$http.get('api/cities', {params})
          .then(({data})  => {
            this.cities = data.data
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCity = false
          )

    },
    async fetchAreas(val) {

      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;

      const params = {
        "filter[name]" : query.city_name,
        //"filter[country_name]" : query,
        "filter[country_code]" : query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      await this.$http.get('api/areas', {params})
          .then(({data})  => {
            this.cities = data.data;
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          });
      this.formData.step1.city = this.cities.map(a => a.id);
      this.formData.step1.state = this.cities.map(a => a.country_id);


    },
    clearAll () {
      this.cities = []
    }
  },
  watch: {
    /*
   'formData.step4.countries': function(){
    this.formData.step4.countrylist = this.formData.step4.countries.map(a => a.id);
    },
    'formData.step4.cities': function(){
    this.formData.step4.citylist = this.formData.step4.cities.map(a => a.id);
    },
    */
    searchCities(val) {

      if (this.searchCities == null || this.searchCities.length < 3 ) {
        return
      }

      setTimeout(function(){
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this),300);

      /*
      if(this.searchCities === null) return
      if (this.searchCities.length < 3) return

      // Items have already been loaded
      //if (this.formData.step4.cities.length > 0) return

      // Items have already been requested
      if (this.loadingCities) return

      this.loadingCities = true


      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      //debounce(this.fetchCities, 200)(val, this)

      setTimeout(function(){
          this.fetchCities(val)
      }.bind(this),200);

      */

    },
    selectedCities: function(){

      if(!this.selectedCities || this.selectedCities === null){
        this.filterCountry = [];
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,

      };
      this.fetchAreas(this.filterArea);

    },
    searchCity(val) {

      if (this.searchCity == null || this.searchCity.length < 3 ) {
        return
      }

      setTimeout(function(){
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this),300);

      /*
      if(this.searchCity === null) return
      if (this.searchCity.length < 3) return

      // Items have already been loaded
      //if (this.formData.step4.cities.length > 0) return

      // Items have already been requested
      if (this.loadingCity) return

      this.loadingCity = true


      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      //debounce(this.fetchCity, 200)(val, this)

      setTimeout(function(){
          this.fetchCity(val)
      }.bind(this),200);
      */


    }


  }

}
</script>

<style>

.phone {
  background: #fff;
  border-radius: 10px;
  padding: 0px;
  transition: all 0.2s;
}
</style>
